import React, { useContext } from 'react'

import styles from './index.module.scss'
import plusIcon from '../../assets/images/plus.svg'
import minusIcon from '../../assets/images/minus.svg'
import { ProductContext } from '../../context/ProductContext'

export default function Counter({
  className,
  customState,
  updateCustomState,
}: {
  className?: string
  customState?: number
  updateCustomState?: (v: number) => void
}) {
  const { productAmount, updateState } = useContext(ProductContext)

  const onPressPlus = () => {
    if (productAmount <= 99) {
      if (!updateCustomState) {
        updateState(productAmount + 1)
      } else {
        updateCustomState((customState || 0) + 1)
      }
    }
  }

  const onPressMinus = () => {
    if (!updateCustomState) {
      productAmount &&
        productAmount > 0 &&
        updateState(productAmount - 1)
    } else {
      customState &&
        customState > 0 &&
        updateCustomState((customState || 0) - 1)
    }
  }

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const value = e.target.value.replace(/[^0-9]/g, '')
    const newValue = +value > 100 ? 100 : +value
    if (!updateCustomState) {
      updateState(newValue)
    } else {
      updateCustomState(newValue)
    }
  }

  return (
    <div
      className={`${styles.counterWrapper} ${className && className}`}
    >
      <button onClick={onPressMinus}>
        <img src={minusIcon} alt="Icon Minus" />
      </button>

      <input
        type="text"
        value={updateCustomState ? customState : productAmount}
        onChange={handleInputChange}
        maxLength={3}
      />

      <button onClick={onPressPlus}>
        <img src={plusIcon} alt="Icon Plus" />
      </button>
    </div>
  )
}

import React from 'react'
import styles from './index.module.scss'

type CartItemProps = {
  className?: string
  name?: string
  description?: string
  price?: number
  currency?: string
}
export default function CartItem({
  className,
  name,
  currency,
  price,
  description,
}: CartItemProps) {
  return (
    <div
      className={`${styles.CartMenuTopBlock} ${
        className && className
      }`}
    >
      <div className={styles.CartMenuImgWrapper}>
        <img
          src={require('../../assets/images/e47_in_cart.png')}
          alt="Element 47 Image"
        />
      </div>
      <div className={styles.CartMenuDescription}>
        <h3>{name}</h3>
        <span>{description}</span>
        <span>{`${price} ${currency}`}</span>
      </div>
    </div>
  )
}

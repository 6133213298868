import React from 'react'

export interface CartModalState {
  isOpen: boolean
  changeStateIsOpen: (newState: boolean) => void
}

const defaultState: CartModalState = {
  isOpen: false,
  changeStateIsOpen: (newState: boolean) => {},
}

export const CartModalContext =
  React.createContext<CartModalState>(defaultState)

import React from 'react'
import ReactDOM from 'react-dom/client'
import './assets/index.scss'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { ProductContextProvider } from './context/ProductContextProvider'
import { CartModalContextProvider } from './context/CartModalContextProvider'

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
)
root.render(
  <React.StrictMode>
    <ProductContextProvider>
      <CartModalContextProvider>
        <App />
      </CartModalContextProvider>
    </ProductContextProvider>
  </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

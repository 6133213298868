import React, { useCallback, useContext, useState } from 'react'
import styles from './index.module.scss'

import { Button } from '../../components/Button'
import { ReactComponent as CartIcon } from '../../assets/images/cart.svg'
import Counter from '../../components/Counter'
import { ProductContext } from '../../context/ProductContext'
import { formatNumberWithThousandsSeparator } from '../../helpers'
import { useGetProducts } from '../../hooks/useGetProducts'

export const WhatIsElement47Section = () => {
  const { productAmount, updateState } = useContext(ProductContext)
  const { productList } = useGetProducts()
  const [amount, setAmount] = useState(0)

  const handleAddToCart = useCallback(() => {
    updateState(productAmount + amount)
    setAmount(0)
  }, [productAmount, amount, updateState])

  const products = productList?.results
  const productPrice = products?.length ? +products?.[0]?.price : 0
  const productCurrency = products?.[0]?.price_currency || '$'

  return (
    <section className={'container'} id="About">
      <h2 className={styles.whatIs_title}>
        <span>
          This is a solution of an organic complex compound{' '}
        </span>
        of ionized silver with an amino acid, obtained by electrolysis
        of silver of 99.99% purity.
      </h2>

      <div className={styles.whatIs_description}>
        <div className={styles.whatIs_img_container}>
          <div className={styles.whatIs_img} />
        </div>

        <div>
          <div className={styles.whatIs_pickup}>Pickup only</div>
          <h3>Element 47 Max</h3>
          <span className={styles.whatIs_details}>
            50 ML / 1.7 FL OZ
          </span>

          <ul className={styles.whatIs_for_list}>
            <li className={styles.whatIs_details}>
              Prevention of diseases caused by coronavirus infection.
              <br /> Reduces the risk of infection to almost zero
            </li>
            <li className={styles.whatIs_details}>
              Prevention of diseases: ARVI, influenza, various viruses
            </li>
            <li className={styles.whatIs_details}>
              Treat the mucous membranes of the mouth, nose and eyes
              2-3 times a day
            </li>
            <li className={styles.whatIs_details}>
              Doesn’t cause discomfort or irritation. Easy to use
            </li>
          </ul>

          <div className={styles.whatIs_for_actions}>
            <div className={styles.whatIs_priceBlock}>
              <span>
                {formatNumberWithThousandsSeparator(
                  (amount * productPrice).toFixed(2),
                )}{' '}
                {productCurrency}
              </span>
              <span>Max order - 100 pcs</span>
            </div>
            <Counter
              customState={amount}
              updateCustomState={setAmount}
            />

            <Button
              onClick={handleAddToCart}
              icon={<CartIcon />}
              typeView="primary"
            >
              Add to cart
            </Button>
          </div>
        </div>
      </div>
    </section>
  )
}

import React from 'react'
import styles from './index.module.scss'

type ButtonBaseProps = {
  icon?: React.ReactNode
  typeView: 'outline' | 'default' | 'primary' | 'link' | 'form'
  className?: string
}

type ButtonProps = ButtonBaseProps &
  Omit<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    keyof ButtonBaseProps
  >

export const Button = ({
  icon,
  children,
  typeView,
  className,
  ...props
}: ButtonProps) => {
  return (
    <button
      className={`
        ${styles.button} 
        ${styles[typeView]}
        ${className ? className : ''}
      `}
      {...props}
    >
      {icon && icon}
      {children}
    </button>
  )
}

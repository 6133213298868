import React from 'react'

export interface ProductState {
  productAmount: number
  updateState: (newState: number) => void
}

const defaultState: ProductState = {
  productAmount: 0,
  updateState: (newState: number) => {},
}

export const ProductContext =
  React.createContext<ProductState>(defaultState)

import React from 'react'

import styles from './index.module.scss'
import { FAQItem } from '../../components/FAQItem'

export const FAQSection = () => {
  return (
    <section className={`container ${styles.faqContainer}`} id="FAQ">
      <h2>Fast asked question</h2>

      <div>
        <FAQItem
          title="What is unique in Element 47 Max?"
          text={
            <>
              <p>
                Unlike dietary supplements made on the basis of
                deionized water and simple microscopic nanoparticles
                of mineral silver, the spray “Element 47 MAX” contains
                ionized trivalent silver, which is absolutely
                non-toxic.
              </p>
              <p>
                Trivalent silver ions in the composition of the spray
                "Element 47 MAX" penetrate through the protein shell
                of viruses, through the membranes of bacteria and,
                reacting with the nitrogenous base of their RNA,
                convert it into the silver oxide salt AgNO3,
                completely destroying the pathogenic activity of
                microbes.
              </p>
            </>
          }
        />
        <FAQItem
          title="How to use spray?"
          text={
            <>
              <p>
                For maximum effect, it is necessary to regularly
                (every 8 hours) apply the spray to the mucous
                membranes of the throat, nose and eyes. This prevents
                bacteria from entering the bloodstream and kills
                harmful viruses.
              </p>
              <p>
                The greatest effectiveness of the spray is achieved
                when sprayed once every 8 hours to prevent diseases.
              </p>
              <p>
                "Element 47 MAX" is not a medicine or dietary
                supplement, which means there are no restrictions on
                the duration of use. “Element 47 MAX” is non-toxic,
                doesn’t accumulate in the body and is absolutely safe
                thanks to its unique formula, which is confirmed by 15
                years of medical research. To prevent viral diseases,
                it is recommended to use the spray daily according to
                the recommended frequency of application.
              </p>
            </>
          }
        />
        <FAQItem
          title="Storage conditions"
          text={
            <>
              <p>
                The shelf life of the drug “Element 47 MAX” in the
                finished solution is 2 years. Store in a dry place at
                a temperature not exceeding 25°C.
              </p>
            </>
          }
        />
        <FAQItem
          title="Safety precautions when using spray"
          text={
            <>
              <p>
                If you have any doubts, it is best to consult your
                doctor. Limitations may include: individual
                intolerance to components, pregnancy and feeding,
                increased nervous excitability, insomnia, high blood
                pressure, severe atherosclerosis, severe liver and
                kidney dysfunction, cardiovascular diseases. And also
                under 18 years of age.
              </p>
              <p>
                We rely on an extensive research base and can
                guarantee that after undergoing thorough testing, the
                spray "Element 47 MAX" is completely natural and
                harmless.
              </p>
            </>
          }
        />
        <FAQItem
          title="How to receive an order?"
          text={
            <p>
              After paying for the order, you will receive the address
              and phone number of the order pickup point, also you get
              a QR code to receive your order.
            </p>
          }
        />
        <FAQItem
          title="How to get a discount?"
          text={
            <p>
              For large orders, there is a personal discount system
              for our customers. In case of a large order, please
              contact us before placing the order to receive a
              discount.
            </p>
          }
        />
      </div>
    </section>
  )
}

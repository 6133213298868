import React from 'react'
import styles from './index.module.scss'

export const ComponentsSection = () => {
  return (
    <section
      className={`container ${styles.componentsContainer_margin}`}
    >
      <div className={styles.componentsContainer}>
        <h2>
          When selecting components, we rely <br /> on internationally
          recognized research <br /> scientific community
        </h2>
        <ul
          className={`${styles.componentsEffectiveIn} ${styles.whatIs_for_list}`}
        >
          <li>Destroy the protein shell of viruses</li>
          <li>
            They attack RNA viruses and prevent their reproduction
          </li>
        </ul>
        <div className={styles.componentsImg}>
          <div className={styles.componentsImgElements} />
        </div>
      </div>
    </section>
  )
}

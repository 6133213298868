import React from 'react'

import styles from './index.module.scss'
import { Button } from '../../components/Button'

export const AllDocumentsSection = () => {
  const handleClick = () => {
    window.location.href = '/researches'
  }

  return (
    <section className={`container ${styles.allDocContainer}`}>
      <h2>
        All documents related to scientific research on spray Element
        47 Max
      </h2>
      <Button typeView="default" onClick={handleClick}>
        Read More
      </Button>
    </section>
  )
}

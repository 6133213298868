import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'

import styles from './index.module.scss'
import { fetchOrderById, OrderInfoProps } from '../../api'
import { formatNumberWithThousandsSeparator } from '../../helpers'
import logo from '../../assets/images/logo.svg'

export const OrderDetailsPage = () => {
  const { id } = useParams()
  const [order, setOrder] = useState<OrderInfoProps>()

  useEffect(() => {
    if (id && !order?.id) {
      fetchOrderById(id, setOrder)
    }
  }, [id])

  const orderPrice = order?.price
    ? formatNumberWithThousandsSeparator((+order.price).toFixed(2))
    : 0
  const priceForOnePiece = order?.price
    ? formatNumberWithThousandsSeparator(
        (+order?.price / order.amount).toFixed(2),
      )
    : orderPrice

  return (
    <main>
      <section>
        <div className={styles.OrderHeader}>
          <div className={styles.OrderContainer}>
            <Link to="/">
              <img src={logo} alt="Element 47 Logo" />
            </Link>
            <h1>Order details</h1>
            <span>Order № {order?.id}</span>
          </div>
        </div>
        <div className={styles.OrderContainer}>
          <img
            src={require('../../assets/images/order_page.png')}
            alt="Element 47"
            className={styles.OrderImg}
          />
          <h2>Details</h2>
          <div className={styles.OrderInfo}>
            <span>Payer’s name</span>
            <span>
              {order?.customer_name} {order?.customer_surname}
            </span>
          </div>
          <div className={styles.OrderInfo}>
            <span>Price</span>
            <span>
              {priceForOnePiece} {order?.price_currency}
            </span>
          </div>
          <div className={styles.OrderInfo}>
            <span>Quantity</span>
            <span>{order?.amount}</span>
          </div>
          <div className={styles.OrderInfo}>
            <span>Total price</span>
            <span>
              {orderPrice} {order?.price_currency}
            </span>
          </div>
        </div>
      </section>
    </main>
  )
}

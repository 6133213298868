import React, { useState } from 'react'
import { FormikProps } from 'formik'

import styles from './index.module.scss'

type InputProps = React.InputHTMLAttributes<HTMLInputElement> & {
  formik: FormikProps<any>
  fieldName: string
}
export default function Input({
  formik,
  fieldName,
  placeholder,
  className,
  ...props
}: InputProps) {
  const [isActive, setIsActive] = useState(false)
  const { errors, touched, handleChange, handleBlur, values } = formik
  const onHandleBlur = (value: any) => {
    setIsActive(false)
    handleBlur(value)
  }

  return (
    <div className={styles.InputContainer}>
      <label
        className={`
          ${isActive ? styles.InputLabelActive : styles.InputLabel}
          ${
            touched[fieldName] && errors[fieldName]
              ? styles.InputLabelError
              : ''
          }
        `}
      >
        {placeholder}
      </label>
      <input
        {...props}
        placeholder={placeholder}
        name={fieldName}
        onChange={handleChange}
        onBlur={onHandleBlur}
        value={values[fieldName]}
        className={`${
          touched[fieldName] && errors[fieldName]
            ? styles.FormErrorInput
            : ''
        } ${className ? className : ''}`}
        onClick={() => setIsActive(true)}
      />
      {touched[fieldName] && errors[fieldName] && (
        // @ts-ignore
        <span className={styles.FormError}>{errors[fieldName]}</span>
      )}
    </div>
  )
}

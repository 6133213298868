import React, { useState } from 'react'
import { CartModalContext } from './CartModalContext'

interface Props {
  children: React.ReactNode
}

export const CartModalContextProvider: React.FunctionComponent<
  Props
> = (props: Props) => {
  const [state, setState] = useState<boolean>(false)

  const changeStateIsOpen = (newState: boolean) => {
    setState(newState)
  }

  return (
    <CartModalContext.Provider
      value={{ isOpen: state, changeStateIsOpen }}
    >
      {props.children}
    </CartModalContext.Provider>
  )
}

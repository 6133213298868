import React from 'react'
import styles from './index.module.scss'

export const UniquenessSection = () => {
  return (
    <section
      className={`container ${styles.uniquenessWrapper}`}
      id="Unique"
    >
      <div>
        <h2>
          Uniqueness of <br />
          Element 47 Max
        </h2>

        <p>
          The highly effective spray “Element 47 MAX” is enriched with
          trivalent silver ions and silver glycinate (an organic
          compound of silver with glycine).
        </p>
        <p>
          This composition is characterized not only by the absence of
          toxicity and antiviral properties, but also by a powerful
          regenerative effect that promotes the healing of affected
          tissues and cells of the human body.
        </p>
        <p>
          The technology for manufacturing the drug “Element 47 MAX”
          is patented by ETK Pharmaceuticals.
        </p>
      </div>

      <div className={styles.uniquenessImgContainer}>
        <div className={styles.uniquenessImg} />
      </div>
    </section>
  )
}

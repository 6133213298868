import React from 'react'
import styles from './index.module.scss'

import { ReviewItem } from '../../components/ReviewItem'

export const ReviewSection = () => {
  return (
    <section
      className={`container ${styles.reviewContainer}`}
      id="Reviews"
    >
      <ReviewItem
        reviewText="I've been looking for a product
         to prevent colds and viruses for a long time.
         I have a large active family. I needed something
         easy to use, portable, and versatile.
         'Element 47 MAX' spray is perfect for
          the whole family! We use it twice a day,
          it's very simple and effective!! I also
          use it for treating my children's scratches and wounds."
        authorName="Olga"
        authorProfession="Manager"
        authorPhoto={
          <img
            src={require('../../assets/images/Olga.png')}
            alt="Olga photo"
          />
        }
        className={styles.reviewPositionLeft}
      />
      <ReviewItem
        reviewText="In spring, I tend to get sick very quickly.
        I was looking for a preventive measure against the flu,
        colds, and viruses. I found 'Element 47 MAX' spray.
        I ordered it online, and it was delivered very quickly.
        The product is easy to use, and it really works.
        I haven't been sick for a long time and feel full
        of energy. I highly recommend it."
        authorName="Artyom"
        authorProfession="Manager"
        authorPhoto={
          <img
            src={require('../../assets/images/Artyom.png')}
            alt="Artyom photo"
          />
        }
      />
      <ReviewItem
        reviewText="I travel a lot, and sometimes I get sick
        during my trips. It's challenging to find a suitable
         remedy for colds and flu in other countries, and
         carrying a bunch of medications is inconvenient.
         In this regard, 'Element 47 MAX' spray suited
         me well because it has both antiviral and antibacterial
         properties. Plus, it's really easy to use. I will
         definitely buy it again."
        authorName="Michael"
        authorProfession="Manager"
        authorPhoto={
          <img
            src={require('../../assets/images/Michael.png')}
            alt="Michael photo"
          />
        }
        className={styles.reviewPositionRight}
      />
    </section>
  )
}

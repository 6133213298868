import { validateRussianPhoneNumber } from '../../helpers'
import { FormProps } from './index'

export const validate = (values: FormProps) => {
  const errors = {} as FormProps

  // if (!values.country) {
  //   errors.country = 'Required'
  // }
  //
  // if (!values.state) {
  //   errors.state = 'Required'
  // }
  //
  // if (!values.city) {
  //   errors.city = 'Required'
  // }
  //
  // if (!values.addressLine1) {
  //   errors.addressLine1 = 'Required'
  // }
  //
  // if (!values.zip) {
  //   errors.zip = 'Required'
  // }

  if (!values.firstName) {
    errors.firstName = 'Required'
  }

  if (!values.lastName) {
    errors.lastName = 'Required'
  }

  if (!values.email) {
    errors.email = 'Required'
  } else if (
    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
  ) {
    errors.email = 'Invalid email address'
  }

  if (!values.phone) {
    errors.phone = 'Required'
  } else if (!validateRussianPhoneNumber(values.phone)) {
    errors.phone = 'Invalid phone'
  }

  return errors
}

import React, { useContext, useState } from 'react'
import Modal, { Props as ReactModalProps } from 'react-modal'
import { useFormik } from 'formik'

import styles from './index.module.scss'
import { ProductContext } from '../../context/ProductContext'
import { useGetProducts } from '../../hooks/useGetProducts'
import closeIcon from '../../assets/images/close.svg'
import CartForm from './CartForm'
import CartItem from '../CartItem'
import { formatNumberWithThousandsSeparator } from '../../helpers'
import { Button } from '../Button'
import { Link } from 'react-router-dom'
import { putOrder } from '../../api'
import { validate } from './formValidation'
import Counter from '../Counter'
import Input from '../Input'

type CartModalType = 'form' | 'success' | 'error'

export interface FormProps {
  firstName: string
  lastName: string
  email: string
  phone: string
}
export default function CartModal({
  isOpen,
  onAfterOpen,
  onRequestClose,
  style,
}: ReactModalProps) {
  const [formType, setFormType] = useState<CartModalType>('form')
  const { productAmount, updateState } = useContext(ProductContext)
  const { productList } = useGetProducts()

  const products = productList?.results
  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
    },
    validate,
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      setSubmitting(false)
      console.log('result')
      const result = await putOrder({
        amount: productAmount.toString(),
        customer_name: values.firstName,
        customer_surname: values.lastName,
        email: values.email,
        phone: values.phone,
        // shipping_info: `${values.addressLine1}, ${values.addressLine2}, ${values.city}, ${values.state}, ${values.country}, ${values.zip}`,
        product_id: products?.[0]?.id!,
        payment_system: 'nexi_it',
      })
      console.log('result', result)
      if (result) {
        // setFormType('success')
        window.location.href = result
      } else {
        setFormType('error')
      }
      resetForm()
      updateState(0)
    },
  })

  const handleClose = (
    event: React.MouseEvent | React.KeyboardEvent,
  ) => {
    setFormType('form')
    onRequestClose && onRequestClose(event)
  }

  const productPrice = products?.length ? +products?.[0]?.price : 0
  const productName = products?.[0]?.name
  const productDescription = products?.[0]?.description || ''
  const productCurrency = products?.[0]?.price_currency || '$'
  const isFormType = formType === 'form'
  const formikValues = formik.values
  const disabledPayBtn =
    productAmount === 0 ||
    !formikValues.firstName ||
    !formikValues.lastName ||
    !formikValues.email ||
    !formikValues.phone

  return (
    <Modal
      isOpen={isOpen}
      onAfterOpen={onAfterOpen}
      onRequestClose={handleClose}
      style={style}
      className={`${styles.Modal} ${
        isFormType ? '' : styles.ModalSmall
      }`}
      overlayClassName={styles.Overlay}
    >
      {!isFormType ? (
        <>
          <button
            onClick={handleClose}
            className={styles.ModalBtnClose}
          >
            <img src={closeIcon} alt="Close Icon" />
          </button>
          <img
            src={require(`../../assets/images/${formType}.svg`)}
            alt="Success Icon"
          />

          <h2>{formType === 'success' ? 'Paid' : 'Error'}</h2>
          <p>
            {formType === 'success'
              ? 'We are processing your payment.' +
                'You will receive a confirmation email shortly'
              : 'An error occurred during payment \n' +
                'or the order was not paid'}
          </p>
        </>
      ) : (
        <>
          <button
            onClick={handleClose}
            className={`${styles.ModalBtnClose} ${styles.ModalBtnCloseMobile}`}
          >
            <img src={closeIcon} alt="Close Icon" />
          </button>
          <div className={styles.FormBlock}>
            <h2>Payment details</h2>
            <h3>Shipping information</h3>

            <p>Pickup only.</p>
            <p>
              After placing an order, you will receive the address and
              contact details of the pickup location, also you get a
              QR code to receive your order.
            </p>
            <CartForm formik={formik} />
          </div>

          <div className={styles.PaymentBlock}>
            <div>
              <h2>Order summary</h2>
              <CartItem
                price={productPrice}
                name={productName}
                description={productDescription}
                currency={productCurrency}
                className={styles.CartItem}
              />
              <Counter className={styles.Counter} />
              <Input
                formik={formik}
                fieldName="promocode"
                placeholder="Promo code"
                className={styles.Promocode}
              />
            </div>
            <div>
              <div className={styles.PriceInfo}>
                <span>Price</span>
                <span>{`${productPrice} ${productCurrency}`}</span>
              </div>
              <div className={styles.PriceInfo}>
                <span>Qty</span>
                <span>x{productAmount}</span>
              </div>
              <div className={styles.PriceInfo}>
                <span>Total</span>
                <span>
                  {formatNumberWithThousandsSeparator(
                    (productAmount * productPrice).toFixed(2),
                  )}{' '}
                  {productCurrency}
                </span>
              </div>
              <Button
                typeView="form"
                className={styles.PaymentBlockPaymentBtn}
                disabled={disabledPayBtn}
                onClick={() => formik.submitForm()}
              >
                Confirm and Pay
              </Button>
              <p className={styles.PaymentBlockDescription}>
                By clicking the 'Confirm and Pay' button, <br />
                I confirm that I have read the offer posted at the
                link <br />
                <Link to="/offer" onClick={handleClose}>
                  elm47.com/offer{' '}
                </Link>
                and accept it in full, along with the <br /> essential
                the contract presented to me by the company 'BALZI
                ROSSI S.R.L'.
              </p>
            </div>
          </div>
        </>
      )}
    </Modal>
  )
}

import React, { useState } from 'react'
import { ProductContext } from './ProductContext'

interface Props {
  children: React.ReactNode
}

export const ProductContextProvider: React.FunctionComponent<
  Props
> = (props: Props) => {
  const [state, setState] = useState<number>(0)

  const updateState = (newState: number) => {
    setState(newState)
  }

  return (
    <ProductContext.Provider
      value={{ productAmount: state, updateState }}
    >
      {props.children}
    </ProductContext.Provider>
  )
}

import { useEffect, useState } from 'react'

import { URL } from './constants'

type Product = {
  id: string
  created_at: string
  updated_at: string
  name: string
  description: string
  price_currency: string
  price: string
}

type ProductList = {
  count: number
  next?: string
  previous?: string
  results: Product[]
}
export const useGetProducts = () => {
  const [productList, setProductList] = useState<ProductList>()
  const [isPending, setIsPending] = useState(false)
  const [error, setError] = useState<null | string>(null)

  useEffect(() => {
    if (!productList) {
      const fetchData = async () => {
        setIsPending(true)
        try {
          const response = await fetch(URL('products'))
          if (!response.ok) throw new Error(response.statusText)
          const json = await response.json()
          setIsPending(false)
          setProductList(json)
          setError(null)
        } catch (error) {
          setError(`${error} Could not Fetch Data `)
          setIsPending(false)
        }
      }
      fetchData()
    }
  }, [])

  return { productList, isPending, error }
}

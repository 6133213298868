import React from 'react'
import styles from './index.module.scss'

interface ReviewItemProps {
  reviewText: string
  authorName: string
  authorProfession: string
  authorPhoto: React.ReactNode
  className?: string
}
export const ReviewItem = ({
  reviewText,
  authorProfession,
  authorPhoto,
  authorName,
  className,
}: ReviewItemProps) => {
  return (
    <div className={`${styles.reviewContainer} ${className || ''}`}>
      <div className={styles.review}>{reviewText}</div>

      <div className={styles.reviewAuthor}>
        <div>{authorPhoto}</div>
        <div className={styles.author}>
          <span className={styles.authorName}>{authorName}</span>
          <span className={styles.authorProfession}>
            {authorProfession}
          </span>
        </div>
      </div>
    </div>
  )
}

import React from 'react'
import { Link } from 'react-router-dom'

import styles from './index.module.scss'
import pdfIcon from '../../assets/images/pdf.svg'

const docs = [
  {
    title: 'REPORT on the results of the study: No. 0044/21',
    desc: 'Virulicidal Activity of Silver-Containing Solution Against SARS-CoV-2 in vitro',
    date: '20.12.21',
    file: '0044-21.pdf',
  },
  {
    title: 'REPORT on the results of the study: No. 0014/22',
    desc: 'The study investigates the antiviral effects of silver-containing sprays with varying concentrations against SARS-CoV-2',
    date: '20.12.21',
    file: '0014-22.pdf',
  },
  {
    title:
      'Report on the topic of studying the effectiveness of the drug Comparol in monkeys',
    desc: 'Antiviral effectiveness and safety of compargol in monkeys \n',
    date: '20.12.21',
    file: 'Report-on-the-topic-of-studying-the-effectiveness-of the-drug-Comparol-in-monkeys.pdf',
  },
  {
    title:
      'Report Research on the antiviral activity of the drug Comparol\n',
    desc: 'he research on the antiviral activity of the drug Compargol against influenza infection\n',
    date: '20.12.21',
    file: 'Report-Research-on-the-antiviral-activity-of-the-drug-Comparol.pdf',
  },
  {
    title: 'Reference, Report on scientific research work\n',
    desc: 'The study of antiviral activity of the drug Element 47 against coronavirus (HCoV-OC43)',
    date: '20.12.21',
  },
  {
    title: 'Report Pneumonia Pathogens',
    desc: 'Antimicrobial effect on various pneumonia-causing pathogens.\n',
    date: '20.12.21',
  },
  {
    title:
      'Report on scientific\nresearch work under\ncontract No. 85 F 20',
    desc: 'Research on determining the protective activity of the silver-containing solution Element 47\n',
    date: '20.12.21',
  },
  {
    title:
      'Reference Study of the prophylactic activity of silver-containing',
    desc: "Analysis of the results of the R&D project 'Study of the preventive activity of a silver-containing solution against the SARS-CoV-2 coronavirus in laboratory animals'",
    date: '20.12.21',
  },
  {
    title: 'Reference Element 47 and Element 47 MAX',
    desc: 'Production and Composition of Silver-Containing Hygienic Spray',
    date: '20.12.21',
  },
  {
    title: 'Registry of Reports',
    desc: 'Reports, locations, and years of research conduct',
    date: '20.12.21',
  },
]

export const ResearchesPage = () => {
  return (
    <main className={styles.container}>
      <div className={styles.TitleContainer}>
        <h1 className={'container'}>
          All documents related to scientific research on spray
          Element 47 Max
        </h1>
      </div>
      <section className={`container`}>
        <div className={styles.docList}>
          {docs.map((item) => {
            return (
              <div>
                <a
                  href={process.env.PUBLIC_URL + item.file}
                  download={item.file}
                  className={styles.docPdf}
                >
                  <img src={pdfIcon} alt="Pdf icon" />
                </a>
                <h2>{item.title}</h2>
                <p>{item.desc}</p>
                <span>{item.date}</span>
              </div>
            )
          })}
        </div>
      </section>
    </main>
  )
}

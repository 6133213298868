import axios from 'axios'

import { URL } from '../hooks/constants'
import { Dispatch, SetStateAction } from 'react'

interface OrderProps {
  amount: string
  customer_name: string
  customer_surname: string
  email: string
  phone: string
  // shipping_info: string
  product_id: string
  payment_system: string
}

export interface OrderInfoProps {
  id: string
  product: {
    name: string
    description: string
    price_currency: string
    price: string
    changed_at: string
    id: string
  }
  payment_link: string
  created_at: string
  updated_at: string
  amount: number
  price_currency: string
  price: string
  customer_name: string
  customer_surname: string
  email: string
  phone: string
  shipping_info: string
  public_id: string
  last_payment: string
}

export const putOrder = async (data: OrderProps) => {
  try {
    const formData = new FormData()
    formData.append('amount', data.amount)
    formData.append('customer_name', data.customer_name)
    formData.append('customer_surname', data.customer_surname)
    formData.append('phone', data.phone || '')
    formData.append('email', data.email || '')
    formData.append('shipping_info', 'Pick up')
    formData.append('product_id', data.product_id || '')
    formData.append('payment_system', data.payment_system || '')

    const result = await axios.post(URL('orders/'), formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    return result?.data?.payment_link
  } catch (e) {
    return false
  }
}

export const fetchOrderById = async (
  id: string,
  setOrder: Dispatch<SetStateAction<OrderInfoProps | undefined>>,
) => {
  try {
    const response = await fetch(URL(`public-orders/${id}`))
    const jsonData = await response.json()
    setOrder(jsonData)
    return jsonData
  } catch (error) {
    console.log('Error:', error)
  }
}

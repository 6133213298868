import React from 'react'

import styles from './index.module.scss'

export const OfferPage = () => {
  return (
    <main className={`container ${styles.OfferPageContainer}`}>
      <section>
        <h1>Public Offer</h1>
        <p>
          BALZI ROSSI S.R.L., a company having its registered office
          at Italy, Ventimiglia Via Balzi Rossi 2 CAP 18039,
          hereinafter referred to as the “Seller”, represented by
          Director Mr. Emanuele Mongillo, acting according to the
          Articles of Association, publishes a Public Offer for the
          sale of Goods remotely.
        </p>

        <h2>1. Subject of the Contract</h2>

        <p>
          The Buyer buys, and the Seller undertakes to sell the Goods
          (hereinafter referred to as the Goods) posted on the online
          store website https://elm47.com.
        </p>

        <p>
          Delivery of the Goods shall be effected in accordance with
          the Contract.
        </p>

        <h2>2. Quality and Complementation of the Goods</h2>

        <p>
          2.1. Quality and complementation of the Goods delivered
          under this Contract shall comply with the requirements
          established in the manufacturing country and/or factory
        </p>
        <p>
          2.2. All goods, according to the nomenclature, shall have a
          specific warranty period. The manufacturer ascribes the
          warranty period for each Good or a group of Goods and it
          shall be specified in a label of Goods.
        </p>
        <p>
          2.3. The validity period of the Public Offer is unlimited,
          unless otherwise indicated on the website of the online
          store.
        </p>

        <h2>3. Price of the Goods and Payment Conditions</h2>

        <p>
          3.1. Price for the Goods is indicated on the website of the
          online store.
        </p>
        <p>
          3.2. The Buyer shall effect payments to the Seller’s account
          stated in the present Contract or any other account
          specified by the Seller.
        </p>
        <p>3.3. Term of the payment – 100% prepayment.</p>
        <p>
          3.4 Payments between the Seller and the Buyer for the Goods
          are made in the ways specified on the website of the online
          store.
        </p>

        <h2>4. Ordering, Dispatch and Receipt of the Goods</h2>

        <p>
          4.1. Terms of delivery of the Goods - Incoterms 2020 EXW 250
          M2 Warehouse Q3-098 SAIF Zone Sharjah U.A.E. (the Buyer
          withdraws the Goods from the Seller’s warehouse by himself,
          pickup).
        </p>
        <p>
          4.2. To place an order, the Buyer shall sign current
          Contract electronically on the website, by pressing the BUY
          button or using any available mode of communication.
        </p>
        <p>
          4.3. According to the Goods specification (Hygiene Goods
          Category) the Goods are non-returnable, except for cases
          specified in paragraph 6.3 and cannot be refunded.
        </p>
        <p>
          4.4. The Seller guarantees the availability of the Goods in
          stock at the address specified in paragraph 4.1
        </p>
        <p>
          4.5. The Buyer or a the third party on behalf of the Buyer
          shall verify the conformity of the Goods to the order, their
          completeness, quantity and quality at the moment of
          acceptance of the delivery according to paragraph 4.1. of
          the Contract.
        </p>

        <h2>5. Dispute Resolution</h2>

        <p>
          5.1 Both Parties shall undertake all the reasonable efforts
          to settle any disputes arising out of or relating to the
          present Contract in a friendly way. The claim procedure for
          resolving disagreements is mandatory for the Parties under
          this Agreement. The period for consideration of a claim is
          10 (ten) working days from the date of its receipt.
        </p>
        <p>
          5.2 In the event that the Parties cannot reach agreement,
          then all disputes and disagreements must be settled in the
          Dubai International Arbitration Centre in accordance legal
          system of the UAE.
        </p>
        <p>
          5.3. This Contract is governing by the laws of the United
          Arab Emirates.
        </p>

        <h2>6. Term of the Contract and Other Conditions</h2>

        <p>
          6.1. By signing of the current Contract, the Buyer agrees
          that on the signing date hereof the Seller’s representative
          has made quantitative and qualitative acceptance of Goods,
          and the Goods are awaiting pickup by the Buyer in the
          Seller’s warehouse. Signing of any additional documents of
          the Goods acceptance by the Buyer is not required; this
          Contract is a confirmation that the Buyer has accepted the
          title of Goods.
        </p>
        <p>
          6.2. After signing of this Contract and acceptance of the
          Goods by the Seller’s representative, the Goods shall be
          kept in the Seller’s warehouse until the expiration date.
          After the expiration date the Goods shall be destroyed.
        </p>
        <p>
          6.3. If the Goods, received by the Buyer, is of inadequate
          quality, the Buyer is obliged to carry out an examination of
          the Goods within 1 (one) business day from the date of
          receipt of the Goods. If the expert’s conclusion establishes
          that the Buyer received the Goods of inadequate quality, the
          Seller is obliged, by a court decision, to reimburse the
          Buyer for the cost of the examination and documented losses.
        </p>
        <p>
          6.4.The Contract shall become effective from the moment it
          is signed and shall stay in force until the Parties fully
          fulfill all their obligations.The Seller: BALZI ROSSI S.R.L.
        </p>
        <div className={styles.OfferPageBankInfo}>
          <div>
            <span>The Seller: </span>
            <span>BALZI ROSSI S.R.L.</span>
          </div>
          <div>
            <span>Address:</span>
            <span>
              Italy, Ventimiglia Via Balzi Rossi 2 CAP 18039
            </span>
          </div>
          <div>
            <span>Bank:</span>
            <span>Banca di Caraglio Filiale di Bordighera</span>
          </div>
          <div>
            <span>IBAN (EUR):</span>
            <span>IT89Q0843948960000210102257</span>
          </div>
          <div>
            <span>BIC/SWIFT:</span>
            <span>CCRTIT2TCAR</span>
          </div>
          <div>
            <span>Bank:</span>
            <div className={styles.OfferPageBankDetails}>
              Bank: BANCO BPM <br />
              Dip. 2894 Ventimiglia Ag. 1 <br />
              No. Conto 001200 BALZI ROSSI S.R.L.
            </div>
          </div>
          <div>
            <span>Codice SWIFT:</span>
            <span>BAPPIT21U94</span>
          </div>
          <div>
            <span>Codice IBAN:</span>
            <span>IT89 A 05034 49110 000000001200</span>
          </div>
        </div>
        <p>Signed by Mr. Emanuele Mongillo</p>
      </section>
    </main>
  )
}

import React from 'react'
import styles from './index.module.scss'

export const FeaturesSection = () => {
  return (
    <section
      className={`container ${styles.featuresContainer}`}
      id="Features"
    >
      <div
        className={`${styles.featuresItem} ${styles.featuresProtection}`}
      >
        <span className={styles.featuresTitle}>Protection</span>
        <span className={styles.featuresDescription}>
          Antiviral protection for the whole family
        </span>

        <img
          src={require('../../assets/images/protection.png')}
          alt="Protection Image"
        />
      </div>

      <div
        className={`${styles.featuresItem} ${styles.features30years}`}
      >
        <span className={styles.featuresTitle}>30 years</span>
        <span className={styles.featuresDescription}>
          of biological research
        </span>

        <img
          src={require('../../assets/images/microscope.png')}
          alt="Microscope"
        />
      </div>

      <div
        className={`${styles.featuresItem} ${styles.featuresHeart}`}
      >
        <span className={styles.featuresTitle}>
          Prevents infection
        </span>
        <span className={styles.featuresDescription}>
          with acute respiratory viral infections, influenza and
          coronavirus
        </span>

        <img
          src={require('../../assets/images/heart.png')}
          alt="Heart Image"
        />
      </div>

      <div
        className={`${styles.featuresItem} ${styles.featuresVirus}`}
      >
        <span className={styles.featuresTitle}>Destroys viruses</span>
        <span className={styles.featuresDescription}>
          and prevents their <br />
          penetration into the body
        </span>

        <img
          src={require('../../assets/images/virus.png')}
          alt="Virus Image"
        />
      </div>

      <div
        className={`${styles.featuresItem} ${styles.featuresUnique}`}
      >
        <span className={styles.featuresTitle}>Unique formula</span>
        <span className={styles.featuresDescription}>
          with trivalent silver ions and silver glycinate
        </span>

        <img
          src={require('../../assets/images/ag_47.png')}
          alt="Ag 47 element Image"
        />
      </div>
    </section>
  )
}

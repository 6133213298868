import React, { useContext } from 'react'
import styles from './index.module.scss'
import { ReactComponent as CartIcon } from '../../assets/images/cart.svg'
import { Button } from '../../components/Button'
import { CartModalContext } from '../../context/CartModalContext'

export const MainSection = () => {
  const { changeStateIsOpen } = useContext(CartModalContext)
  const onReadMore = () => {
    window.location.href = '#About'
  }

  const handleClickBuyNow = () => {
    changeStateIsOpen(true)
  }

  return (
    <section className={styles.container}>
      <div className={`container ${styles.wrapper}`}>
        <div className={styles.description}>
          <span className={styles.note}>
            Prevent the occurrence of diseases!
          </span>
          <h1 className={styles.h1}>
            Reliable <span>protection</span> against coronavirus
          </h1>
          <p>
            A highly effective spray with proven preventive antiviral
            activity.
          </p>

          <div className={styles.mainActions}>
            <Button
              onClick={handleClickBuyNow}
              icon={<CartIcon />}
              typeView="primary"
            >
              Buy now
            </Button>
            <Button onClick={onReadMore} typeView="default">
              Read More
            </Button>
          </div>
        </div>

        <div className={styles.image} />
        <div className={styles.mainMobileGradient} />
      </div>
    </section>
  )
}

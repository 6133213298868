import React from 'react'
import { FormikProps } from 'formik'

import styles from './index.module.scss'
import Input from '../Input'

export default function CartForm({
  formik,
}: {
  formik: FormikProps<any>
}) {
  return (
    <form
      onSubmit={(e) => {
        e?.preventDefault()
        formik.handleSubmit()
      }}
      className={styles.Form}
    >
      <h3>Customer information</h3>

      <Input
        formik={formik}
        fieldName="firstName"
        placeholder="First Name"
        required
      />
      <Input
        formik={formik}
        fieldName="lastName"
        placeholder="Last Name"
        required
      />
      <Input
        formik={formik}
        fieldName="email"
        placeholder="Email"
        required
        type="email"
      />
      <Input
        formik={formik}
        fieldName="phone"
        placeholder="Phone"
        required
        type="tel"
      />
    </form>
  )
}

import React, { useContext, useRef } from 'react'

import styles from './index.module.scss'
import Counter from '../Counter'
import { ProductContext } from '../../context/ProductContext'
import { ReactComponent as TrashIcon } from '../../assets/images/trash.svg'
import { Button } from '../Button'
import { useGetProducts } from '../../hooks/useGetProducts'
import { formatNumberWithThousandsSeparator } from '../../helpers'
import { CartModalContext } from '../../context/CartModalContext'
import CartItem from '../CartItem'

interface CartMenuProps {
  onOutsideClick: () => void
  className?: string
}
export default function CartMenu({
  onOutsideClick,
  className,
}: CartMenuProps) {
  const ref = useRef()
  const { productAmount, updateState } = useContext(ProductContext)
  const { changeStateIsOpen } = useContext(CartModalContext)
  const { productList } = useGetProducts()

  console.log('productAmount', productAmount)

  React.useEffect(() => {
    const handleClick = (event: any) => {
      if (
        event.target.dataset.cartType !== 'btn-cart' &&
        event.target.dataset.cartType !== 'btn-cart-amount' &&
        event.target.alt !== 'Icon Cart' &&
        ref.current &&
        // @ts-ignore
        !ref.current.contains(event.target)
      ) {
        onOutsideClick()
      }
    }

    document.addEventListener('click', handleClick)

    return () => {
      document.removeEventListener('click', handleClick)
    }
  }, [ref])

  const handleDelete = () => {
    updateState(0)
  }

  const handleOpenCartModal = () => {
    onOutsideClick()
    changeStateIsOpen(true)
  }

  const products = productList?.results
  const productPrice = products?.length ? +products?.[0]?.price : 0
  const productName = products?.[0]?.name
  const productDescription = products?.[0]?.description || ''
  const productCurrency = products?.[0]?.price_currency || '$'

  return (
    <div
      className={`${styles.CartMenuWrapper} ${className}`}
      // @ts-ignore
      ref={ref}
    >
      <CartItem
        price={productPrice}
        name={productName}
        description={productDescription}
        currency={productCurrency}
      />

      <div className={styles.CartMenuBottomBlock}>
        <Counter className={styles.Counter} />

        <div>
          <div className={styles.CartMenuTotalWrapper}>
            <span>Total</span>
            <span>
              {formatNumberWithThousandsSeparator(
                (productAmount * productPrice).toFixed(2),
              )}{' '}
              {productCurrency}
            </span>
          </div>
          <Button
            onClick={handleOpenCartModal}
            typeView="primary"
            className={styles.CartMenuBtn}
          >
            Go to checkout
          </Button>
          <Button
            icon={<TrashIcon />}
            typeView="link"
            className={`${styles.CartMenuBtn} ${styles['CartMenuBtn-margin']}`}
            onClick={handleDelete}
          >
            Delete from cart
          </Button>
        </div>
      </div>
    </div>
  )
}

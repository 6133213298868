import React from 'react'
import { useParams } from 'react-router-dom'

import styles from './index.module.scss'

export const OrderStatusPage = () => {
  const params = useParams()
  const isSuccess = params?.status === 'success'
  return (
    <main className={`container ${styles.OrderStatusContainer}`}>
      <section className={styles.StatusInfoWrapper}>
        <img
          src={require(
            `../../assets/images/${
              isSuccess ? 'success' : 'error'
            }.svg`,
          )}
          alt="Success Icon"
        />

        <h2>{params?.status === 'success' ? 'Paid' : 'Error'}</h2>
        <p>
          {isSuccess
            ? 'We are processing your payment.' +
              'You will receive a confirmation email shortly'
            : 'An error occurred during payment \n' +
              'or the order was not paid'}
        </p>
      </section>
    </main>
  )
}

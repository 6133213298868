import React, { useCallback, useContext } from 'react'
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom'

import { HomePage } from './pages/HomePage'
import { OfferPage } from './pages/OfferPage'
import { OrderStatusPage } from './pages/OrderStatusPage'
import { OrderDetailsPage } from './pages/OrderDetailsPage'
import { ResearchesPage } from './pages/ResearchesPage'
import NotFound from './pages/NotFound'
import { Header } from './components/Header'
import { Footer } from './components/Footer'
import CartModal from './components/CartModal'
import { CartModalContext } from './context/CartModalContext'
// import PreOrderModal from './components/PreOrderModal'

function App() {
  const { isOpen, changeStateIsOpen } = useContext(CartModalContext)

  const handleCloseCartModal = useCallback(() => {
    changeStateIsOpen(false)
  }, [changeStateIsOpen])

  return (
    <div className="App">
      <Router>
        <Header />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/offer" element={<OfferPage />} />
          <Route
            path="/payment/:id/:status"
            element={<OrderStatusPage />}
          />
          <Route path="/orders/:id" element={<OrderDetailsPage />} />
          <Route path="/researches" element={<ResearchesPage />} />
          <Route path="*" element={<NotFound />} />
        </Routes>

        <Footer />

        <CartModal
          isOpen={isOpen}
          onRequestClose={handleCloseCartModal}
        />
        {/*<PreOrderModal />*/}
      </Router>
    </div>
  )
}

export default App

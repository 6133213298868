import React, {
  useRef,
  useState,
  useEffect,
  useContext,
  memo,
  useCallback,
} from 'react'
import { Link, useLocation } from 'react-router-dom'

import styles from './index.module.scss'
import logo from '../../assets/images/logo.svg'
import cartIcon from '../../assets/images/cart.svg'
import { Nav } from './Nav'
import CartMenu from '../CartMenu'
import { ProductContext } from '../../context/ProductContext'

export const Header = memo(() => {
  const ref = useRef(null)
  const location = useLocation()
  const { productAmount } = useContext(ProductContext)
  const [menuIsOpen, setIsOpenMenu] = useState(false)
  const [isCartMenuOpen, setIsCartMenuOpen] = useState(false)

  const isOrderPage = location.pathname.includes('/orders')

  useEffect(() => {
    // Function to handle window resize event
    const handleResize = () => {
      if (menuIsOpen && window.innerWidth > 663) {
        document.body.classList.remove('menu-open')
        setIsOpenMenu(false)
      }
    }
    // Add event listener for window resize
    window.addEventListener('resize', handleResize)

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [menuIsOpen])

  const handleMenuIconClick = useCallback(() => {
    if (!menuIsOpen) {
      document.body.classList.add('menu-open')
    } else {
      document.body.classList.remove('menu-open')
    }
    setIsOpenMenu(!menuIsOpen)
  }, [menuIsOpen, setIsOpenMenu])

  const handleCartClick = () => {
    setIsCartMenuOpen(!isCartMenuOpen)
  }

  const handleOnOutsideCartMenuClick = () => {
    setIsCartMenuOpen(false)
  }

  return !isOrderPage ? (
    <header
      className={` container ${styles.headerContainer} ${
        menuIsOpen && styles.headerContainerActive
      }`}
    >
      <div
        className={`${styles.header} ${
          menuIsOpen && styles.headerActive
        }`}
      >
        <Link to="/" className={styles.headerLogoLink}>
          <img src={logo} alt="Element 47 Logo" />
        </Link>

        <Nav />

        <button
          className={styles.headerCart}
          onClick={handleCartClick}
        >
          <span data-cart-type="btn-cart" className={styles.cartBG} />
          {productAmount > 0 && (
            <span
              className={styles.productInCart}
              data-cart-type="btn-cart-amount"
            >
              {productAmount}
            </span>
          )}
          <img src={cartIcon} alt="Icon Cart" />
        </button>

        <CartMenu
          className={
            isCartMenuOpen ? styles.showCartMenu : styles.hideCartMenu
          }
          onOutsideClick={handleOnOutsideCartMenuClick}
        />

        <div
          className={`${styles.headerMobileNav} ${
            menuIsOpen && styles.headerMobileNavActive
          }`}
        >
          <div
            ref={ref}
            onClick={handleMenuIconClick}
            className={styles.mobileMenuIcon}
          />
          <div
            className={`${styles.MenuNavIcon} ${
              menuIsOpen && styles.MenuNavIconOpen
            }`}
          />

          <div className={`container ${styles.MobileNav}`}>
            <Nav onLinkClick={handleMenuIconClick} />
          </div>
        </div>
      </div>
    </header>
  ) : null
})

import React from 'react'

import { MainSection } from './MainSection'
import { ComponentsSection } from './ComponentsSection'
import { FeaturesSection } from './FeaturesSection'
import { WhatIsElement47Section } from './WhatIsElement47Section'
import { ReviewSection } from './ReviewSection'
import { UniquenessSection } from './UniquenessSection'
import { FAQSection } from './FAQSection'
import { AllDocumentsSection } from './AllDocumentsSection'

export const HomePage = () => {
  return (
    <main>
      <MainSection />
      <WhatIsElement47Section />
      <ComponentsSection />
      <FeaturesSection />
      <UniquenessSection />
      <AllDocumentsSection />
      <ReviewSection />
      <FAQSection />
    </main>
  )
}

import React from 'react'
import styles from './index.module.scss'

export const Nav = ({
  onLinkClick,
}: {
  onLinkClick?: () => void
}) => {
  const handleClick = (url: string) => {
    onLinkClick && onLinkClick()
    window.location.href = url
  }

  return (
    <nav className={styles.headerNav}>
      <button onClick={() => handleClick('#About')}>About</button>
      <button onClick={() => handleClick('#Features')}>
        Features
      </button>
      <button onClick={() => handleClick('#Unique')}>Unique</button>
      <button onClick={() => handleClick('#Reviews')}>Reviews</button>
      <button onClick={() => handleClick('#FAQ')}>FAQ</button>
    </nav>
  )
}

import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import styles from './index.module.scss'
import { Button } from '../../components/Button'
export default function NotFound() {
  const navigate = useNavigate()

  useEffect(() => {
    document.body.classList.add('not-found-page')

    // Cleanup the event listener when the component unmounts
    return () => {
      document.body.classList.remove('not-found-page')
    }
  }, [])

  const handleHomePage = () => {
    navigate('/')
  }

  return (
    <main className={`container ${styles.NotFoundContainer}`}>
      <div>
        <span className={styles.NotFoundError}>Error 404</span>
        <h1>{`Page\nnot found`}</h1>
        <p>
          {`Sorry, the page you are looking\nfor doesn't exist or has been moved`}
        </p>
        <Button typeView="default" onClick={handleHomePage}>
          Go to homepage
        </Button>
      </div>

      <div>
        <img
          src={require('../../assets/images/404.png')}
          alt="Page 404 Image"
        />
      </div>
    </main>
  )
}

import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import logo from '../../assets/images/logo.svg'
import styles from './index.module.scss'
import useScrollToTop from '../../hooks/useScrollToTop'

export const Footer = () => {
  const location = useLocation()
  useScrollToTop()

  const isOrderPage = location.pathname.includes('/orders')

  return (
    <footer
      className={!isOrderPage ? 'container' : styles.footerContainer}
    >
      <div className={styles.footer}>
        <div>
          <Link to="/">
            <img src={logo} alt="Element 47 Logo" />
          </Link>

          <div className={styles.footerRights}>
            © 2023 BALZI ROSSI S.R.L. · All Right Reserved
          </div>
        </div>

        <Link to="/offer" className={styles.footerLink}>
          Public offer
        </Link>
      </div>
    </footer>
  )
}
